import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../Style/transaction.module.css';
import Arrow from './icons/back Vector';
import  Download from './icons/download';
import axios from 'axios';
import { Spin } from 'antd';
import { antdMessageError, antdMessageSuccess } from '../common/antdMessageComponent';
import { logOutHandlerForMobile } from '../../utills/validator';

const Transaction = ({ setscreen,rightvaluepassed,color }) => {
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [rightvalue, setrightvalue] = useState();
  const [transaction, setTransaction] = useState([]);
  const [purchaseId,setPurchaseId] = useState('')
  const [transactionId,setTransactionId] = useState('')
  const [type,setType] =useState('')
  const [title,setTitle] = useState('')

  const handleRedirect = (path) => {
    navigate(path);
  };

  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const userCode = queryParams.get('accessToken');

  const fetchData = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setdata(response.data);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError("It seems your account has been logged out. Please try logging in again")
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    // Define options for toLocaleDateString
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    // Convert to the desired format
    return date.toLocaleDateString('en-GB', options);
  }
  
  
  useEffect(() => {
    fetchData();
  }, []);

    const downloadinvoice = async (purchaseId,transactionId,type,title) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/download-invoice?purchaseId=${purchaseId}&transactionId=${transactionId}&type=${type}&title=${title}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },        
      });      
      antdMessageSuccess('downloaded Successfully')
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      antdMessageError('Cannot get user Transactions')
    }
    };

    const puppeteer =  '';
    const pdfOutput = '';
    
    (async function() {

      try {
        const browser = await puppeteer.launch({
          executablePath: '/usr/bin/chromium-browser'
        })
        
      // const browser = await puppeteer.launch();
      const page = await browser.newPage();

      await page.setContent(pdfOutput);
      await page.emulateMedia("screen");
      await page.pdf({
        path: "routes/planiton/pdf/mypdf.pdf",
        format: "A4",
        printBackground: true
      });
      await browser.close();
      //process.exit();
    } catch (e) {
      console.log("Our Error", e)
    }
  })();

  const [font, setfont] = useState('20px');
  
  const handleType = (type) => {
    if (type === 'CONTENT') {
      return 'Pay-per-article';
    } else if (type === 'PASS') {
      return 'Pay-per-use';
    } else if (type === 'SUBSCRIPTION') {
      return 'Subscription';
    }
  }

  const handleTitleLength = (title) =>{
    if(title.length > 45){
      return `${title.substring(0,45)}...`
    }else {return title}
  }
  const getTransactions = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/userTransactions?page=1&limit=999`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setTransaction(response.data.userTransactions)
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerposition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue();
    }
  }, [data]);

  useEffect(() => {
    fetchData();
    getTransactions();
  }, []);

  return (
    <>
    
      {loading ? (
        <div
        className={style['outercontainer']}
        style={{
          background:color,
          right: rightvaluepassed,
        }}>
          
          <div className={style['loader-container']}>
          <div className={style['loader']}></div>
           </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvaluepassed,
            background: data?.userProfileCustomization?.backgroundColor || '',
            fontFamily: data?.userProfileCustomization?.fontStyle || 'Roboto',
            color: data?.userProfileCustomization?.primaryTextColor || 'white',
          }}
        >
          <div className={style['head']} style={{ borderBottom:`0.8px solid ${data?.userProfileCustomization?.primaryTextColor}`}}>
            <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
              <Arrow fill={data?.userProfileCustomization?.primaryTextColor || '#fff'} />
            </div>
            <div className={style['topic']}>Transactions Details</div>
          </div>

          <div className={style['content']}>

            {
              transaction.map(item => (
                <div className={style['details']} style={{borderBottom:`0.8px solid ${data?.userProfileCustomization?.primaryTextColor}`}}>
                <div className={style['date']}>
                {/* <span>Date : {item.date.substring(0,10)}</span> */}
                <span>Date : {formatDate(item?.date)}</span>
                <span className={style['dwn']} onClick={()=>downloadinvoice(item?._id,item?.transactionId,item?.type,item?.name?.title)} style={{color:data?.userProfileCustomization?.highlightColor ,display:'flex',alignItems:'center'}}>
                  <Download fill={data?.userProfileCustomization?.highlightColor || '#000'}/> <span>Download invoice</span>
                </span>
              </div>
              <div className={style['price']}>
                <div className={style['name']}>
                  <div className={style['type']} style={{border:`0.5px solid ${data?.userProfileCustomization?.highlightColor}`,color:data?.userProfileCustomization?.highlightColor,fontSize:'12px',fontWeight:'400',padding:'2px 8px',borderRadius:'2px',width:'fit-content'}}> 
                    {handleType(item.type)}
                  </div>
                  <div className={style['planname']} style={{lineHeight:'20px',fontWeight:'400',fontsize:'16px',wordWrap:'wrap'}}> 
                  {/* {handleTitleLength('Artificial intelligence breakthroughs create new ‘brain’ for advanced robots')} */}
                  {handleTitleLength(item.name.title)}
                 
                  </div>
                  </div>
                <div className={style['cost']} style={{fontSize:font}}>
                  {`${item.currency} ${item.price}`}
                  </div>
              </div>
              <div className={style['payment']}>
                <div className={style['paymenttype']}>Payment type :</div>
                <div className={style['actualvalue']}>{item?.paymentMode}</div>
              </div>
            </div>
              ))
            }
            

            {/* {data.map(item => (
            <div key={item.id} className="card">
          <h2>{item.title}</h2>
          <p>{item.description}</p>
            </div>
            ))} */}

            
            
            
            
            
            
          </div>
        </div>
      )}
    </>
  );
};

export default Transaction;
