import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import style from '../../Ui/DropDown/commonAntdSelectedBox.module.scss';
import BackArrow_icon from '../assets/loginsettings/backArrow_icon.svg';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import Gender_icon from '../assets/loginsettings/gender_icon.svg';
import Calender_icon from '../assets/loginsettings/calender_icon.svg';
import Search_icon from '../assets/loginsettings/search_icon.svg';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
import { ReactComponent as DropDownArrow } from '../assets/loginsettings/dropDownArrow.svg';
import { Country_flag_json } from '../common/country-flag-json';
import { DatePicker, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkSuccessCode, validateEmail } from '../../utills/validator';
import { setRegisterDetails } from '../../Reducer/commonData';
import dayjs from 'dayjs';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { CSCSendEvent, eventLocation, eventType } from '../../analytics';
import axios from 'axios';
import CTALoader from '../../utills/ctaLoader';

export function UserDataCollection() {
  const queryParams = new URLSearchParams(window.location.search);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state) => state.newClientConfig.userDataCollection);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientDetails = useSelector((state) => state.clientDetails);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const [inputValue, SetInputValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase()),
  );
  const dropdownRender = (menu) => (
    <div>
      <div className={loginStyle['dropDown-search']}>
        <Input placeholder="Search..." onChange={(e) => SetInputValue(e.target.value)} />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );

  const checkinput = (value) => {
    if (clientDetails?.loginType === 'PHONE' && validateEmail(value.toLowerCase())) {
      return dispatch(setRegisterDetails({ ...clientDetails, email: value.toLowerCase() }));
    }
    if (clientDetails?.loginType === 'EMAIL') {
      return dispatch(setRegisterDetails({ ...clientDetails, phone: value }));
    } else {
      dispatch(setRegisterDetails({ ...clientDetails, email: value.toLowerCase() }));
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/register`,
        data: {
          ...(clientDetails?.phone && { phone: clientDetails?.phone, countryCode: '+91' }),
          ...(clientDetails?.email && { email: clientDetails?.email }),
          clientGroupId: clientGroupId,
          primary: clientDetails?.primary,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
          gender: clientDetails.gender,
          dateOfBirth: clientDetails.dateOfBirth,
          name: clientDetails.fullName,
        },
      });
      if (checkSuccessCode(response)) {
        setLoading(false);
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/register', email: clientDetails?.email, phone: clientDetails?.phone },
        });
      }
    } catch (error) {
      setLoading(false);
      return antdMessageError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    (async () => {
      const detectCountry = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/detect-country`,
      });
      if (checkSuccessCode(detectCountry)) {
        const value = Country_flag_json?.find((e) => e?.code === detectCountry?.data?.countryCode);
        localStorage.setItem('dail_code', value?.dail_code);
        dispatch(setRegisterDetails({ ...clientDetails, dail_code: value?.dail_code }));
      }
    })();
  }, []);

  useEffect(() => {
    const ssoSignupSecondaryDetailsViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_SIGNUP_SECONDARY_DETAILS,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
    };
    CSCSendEvent(ssoSignupSecondaryDetailsViewObject);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/register?${queryParams.toString()}`);
    }
  }, [navigate]);

  const ssoSignupClickObject = {
    eventType: eventType.CLICK,
    eventLocation: eventLocation.SSO_SIGNUP,
    clickAction: clientDetails?.loginType,
    clientId: commanUrlValue?.clientId,
    anonId: commanUrlValue?.anonId,
    paywallId: commanUrlValue?.paywallId,
    url: decodeURIComponent(window.location.href || ''),
  };
  useEffect(() => {
    if (toggleData?.secondaryFields?.enabled) {
      if (
        toggleData?.secondaryFields?.enableSecondaryInput?.value === 'MANDATORY' &&
        (!validateEmail(clientDetails?.email) || clientDetails?.phone === '')
      ) {
        return setBtnDisabled(true);
      } else if (
        toggleData?.secondaryFields?.enableNameInput?.value === 'MANDATORY' &&
        clientDetails?.fullName === ''
      ) {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.dob?.value === 'MANDATORY' && clientDetails?.dateOfBirth === '') {
        return setBtnDisabled(true);
      } else if (toggleData?.secondaryFields?.gender?.value === 'MANDATORY' && clientDetails?.gender === '') {
        return setBtnDisabled(true);
      } else {
        return setBtnDisabled(false);
      }
    } else {
      return setBtnDisabled(false);
    }
  }, [clientDetails]);

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        if (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password) {
          return;
        } else if (toggleData?.accessMethods?.password) {
          if (clientDetails?.email !== '' && !validateEmail(clientDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
          CSCSendEvent(ssoSignupClickObject);
          navigate(`/setPassword?${queryParams.toString()}`);
        } else {
          if (clientDetails?.email !== '' && !validateEmail(clientDetails?.email)) {
            return antdMessageWarning('Please enter a valid email address.');
          }
          navigate(`/newvarifyotp?${queryParams.toString()}`, {
            state: { backRoute: '/personalinfo', email: clientDetails?.email, phone: clientDetails?.phone },
          });
        }
      }
    }
  };

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('email');
    queryParams.delete('phone');

    navigate(`/register?${queryParams.toString()}`);
  };

  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['p-4']}></div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['backArrow_icon']} onClick={handleBackClick}>
            <img src={BackArrow_icon} alt="BackArrow" />
          </div>
          <div className={loginStyle['heading-container']}>
            <div>
              <div className={loginStyle['heading-text']} style={data?.formTitle}>
                {data?.formTitle?.text || 'Create new account'}
              </div>
              <div className={loginStyle['sub-text']} style={data?.subtitle}>
                {data?.subtitle?.text || 'Please enter your valid details below'}
              </div>
            </div>
          </div>
          <div className={loginStyle['form-container']}>
            {clientDetails?.loginType === 'PHONE' && toggleData?.loginMethods?.enableMobileLogin && (
              <>
                <div className={loginStyle['input-container-common']}>
                  <img src={Mobile_icon} alt="Mobile_icon" />
                  <Input
                    placeholder="+91435647545345"
                    value={`+91${clientDetails?.phone}`}
                    disabled={clientDetails?.phone ? true : false}
                    style={{
                      backgroundColor: clientDetails?.phone ? '#EBEBEB' : '',
                    }}
                  />
                </div>
                {toggleData?.secondaryFields?.enabled &&
                  toggleData?.secondaryFields?.enableSecondaryInput?.isRequired && (
                    <div className={loginStyle['input-container-common']}>
                      <img src={Email_icon} alt="Email_icon" />
                      <Input
                        placeholder={data?.secondaryLoginInput?.text || 'Email'}
                        value={clientDetails?.email}
                        style={{ ...data?.secondaryLoginInput }}
                        onChange={(e) => checkinput(e.target.value)}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
              </>
            )}
            {clientDetails?.loginType === 'EMAIL' && toggleData?.loginMethods?.enableEmailLogin && (
              <>
                <div className={loginStyle['input-container-common']}>
                  <img src={Email_icon} alt="Email_icon" />
                  <Input
                    placeholder={data?.secondaryLoginInput?.text || 'Email'}
                    value={clientDetails?.email}
                    disabled={clientDetails?.email ? true : false}
                    style={{
                      ...data?.secondaryLoginInput,
                      backgroundColor: clientDetails?.email ? '#EBEBEB' : '',
                    }}
                  />
                </div>
                {toggleData?.secondaryFields?.enabled &&
                  toggleData?.secondaryFields?.enableSecondaryInput?.isRequired && (
                    <div className={loginStyle['input-container']}>
                      <img src={Mobile_icon} alt="Mobile_icon" />
                      <div className={loginStyle['dropDown-container']}>
                        <Select
                          dropdownRender={dropdownRender}
                          popupMatchSelectWidth={250}
                          filterOption={false}
                          suffixIcon={<DropDownArrow />}
                          className={style['common-select-box']}
                          popupClassName={style['common-dropdown-select']}
                          value={localStorage.getItem('dail_code')}
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '14px',
                          }}
                          onChange={(value) =>
                            dispatch(
                              setRegisterDetails({
                                ...clientDetails,
                                dail_code: value,
                              }),
                            )
                          }
                          optionLabelProp="label"
                          onKeyDown={keyDownHandler}
                        >
                          {filteredOptions.map((option, index) => (
                            <Select.Option
                              key={index}
                              value={option.dail_code}
                              label={option.dail_code}
                              className={style['dropdown-options']}
                            >
                              <div className={loginStyle['dropdown-flag']}>
                                <div className={loginStyle['flag-code']} style={{ fontSize: '12px' }}>
                                  {option.dail_code}
                                </div>
                                <div className={loginStyle['flag-name']}>{option.name}</div>
                                <div className={loginStyle['flag']}>{option.flag}</div>
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className={loginStyle['vertical-line']}></div>
                      <Input
                        placeholder={data?.secondaryLoginInput?.text || 'Phone Number'}
                        style={data?.secondaryLoginInput}
                        value={clientDetails?.phone}
                        onChange={(e) => {
                          const reg = /^-?\d+(\.\d*)?$/;
                          if (reg.test(e.target.value) || e.target.value === '') {
                            checkinput(e.target.value);
                          }
                        }}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
              </>
            )}
            {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.enableNameInput?.isRequired && (
              <div className={loginStyle['input-container-common']}>
                <img src={Profile_icon} alt="Profile_icon" />
                <Input
                  placeholder={data?.fullName?.text || 'Full Name'}
                  style={data?.fullName}
                  onChange={(e) =>
                    dispatch(
                      setRegisterDetails({
                        ...clientDetails,
                        fullName: e.target.value,
                      }),
                    )
                  }
                  value={clientDetails?.fullName}
                  onKeyDown={keyDownHandler}
                />
              </div>
            )}
            {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.dob?.isRequired && (
              <div className={loginStyle['input-container-common']}>
                <img src={Calender_icon} alt="Calender_icon" />
                <DatePicker
                  allowClear={false}
                  suffixIcon={false}
                  placeholder={data?.dateOfBirth?.text || 'Date of Birth'}
                  inputReadOnly={true}
                  onChange={(e) => {
                    if (e) {
                      const utcDate = new Date(
                        Date.UTC(e.year(), e.month(), e.date(), e.hour(), e.minute(), e.second(), e.millisecond()),
                      ).toISOString();
                      dispatch(
                        setRegisterDetails({
                          ...clientDetails,
                          dateOfBirth: utcDate,
                        }),
                      );
                    }
                  }}
                  onKeyDown={keyDownHandler}
                  value={clientDetails?.dateOfBirth ? dayjs(clientDetails?.dateOfBirth) : ''}
                  disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                />
              </div>
            )}
            {toggleData?.secondaryFields?.enabled && toggleData?.secondaryFields?.gender?.isRequired && (
              <div className={loginStyle['dropdown-container']}>
                <img src={Gender_icon} alt="Gender_icon" />
                <CustomDropDownForSelect
                  placeholder={data?.gender?.text || 'Gender'}
                  options={{ array: ['MALE', 'FEMALE', 'OTHERS'] }}
                  customStyle={{
                    border: '1px solid #E6E6E6',
                    fontFamily: 'Montserrat',
                    height: '48px',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                  dropdownStyle={{ zIndex: 1 }}
                  hoverColor="#E6E6E6"
                  onKeyDown={keyDownHandler}
                  defaultValue={clientDetails?.gender || undefined}
                  onChangeForSelect={(e) => dispatch(setRegisterDetails({ ...clientDetails, gender: e }))}
                />
              </div>
            )}
          </div>
          {toggleData?.accessMethods?.otp && (
            <button
              className={loginStyle['btn-style']}
              style={{
                ...data?.buttonText,
                opacity: btnDisabled ? '0.5' : '1',
                cursor: btnDisabled ? 'not-allowed' : 'pointer',
              }}
              disabled={btnDisabled || loading}
              onClick={() => {
                if (clientDetails?.email !== '' && !validateEmail(clientDetails?.email)) {
                  return antdMessageWarning('Please enter a valid email address.');
                }
                handleRegister();
              }}
            >
              {loading ? (
                <CTALoader />
              ) : data?.buttonText?.text || (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password) ? (
                'Sign Up via OTP'
              ) : (
                'Send OTP'
              )}
            </button>
          )}
          {toggleData?.accessMethods?.password && clientDetails.loginType !== 'PHONE' && (
            <div className={loginStyle['btn-margin']}>
              <button
                className={loginStyle['btn-style']}
                style={{
                  ...data?.signupViaPassword,
                  opacity: btnDisabled ? '0.5' : '1',
                  cursor: btnDisabled ? 'not-allowed' : 'pointer',
                }}
                disabled={btnDisabled}
                onClick={() => {
                  if (clientDetails?.email !== '' && !validateEmail(clientDetails?.email)) {
                    return antdMessageWarning('Please enter a valid email address.');
                  }
                  CSCSendEvent(ssoSignupClickObject);
                  navigate(`/setPassword?${queryParams.toString()}`, { state: '/personalinfo' });
                }}
              >
                {data?.signupViaPassword?.text ||
                (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password)
                  ? 'Sign Up via Password'
                  : 'Create Password'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
