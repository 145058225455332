import { logOutHandlerForMobile } from '../../utills/validator';

export async function CSCGetAccessToken(refreshToken) {
  if (refreshToken) {
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/authorization/refresh`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    if (resp.status === 200) {
      const data = await resp.json();
      return data.accessToken;
    } else {
      logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
      window.parent.postMessage({ source: 'logout-from-user' }, '*');
      throw new Error('Error refreshing access token');
    }
  } else {
    throw new Error('Error user not logged in');
  }
}
export function CSCCheckJWTExpired(token) {
  if (!token) {
    return true;
  }
  const jwt = token.split('.');
  if (jwt.length !== 3) {
    return true;
  }
  const payload = JSON.parse(atob(jwt[1]));
  const now = new Date();
  return now.getTime() >= payload.exp * 1000;
}

export function userProfileHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.userProfileHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect userProfilehandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.userProfileHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect userProfilehandler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('USER_PROFILE', value);
  } catch (error) {
    console.warn('did not find flutter 2 userProfilehandler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView userProfilehandler');
  }
}

// Function to validate email format
export function isValidEmail(email) {
  if (typeof email !== 'string') return ''; // Ensure the input is a string
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) ? email : '';
}

// Function to validate number format
export function isValidNumber(number) {
  if (typeof number !== 'string') return ''; // Ensure the input is a string
  const numberRegex = /^[0-9]+$/; // Regex to match only digits
  return numberRegex.test(number) ? number : '';
}
