import React, { useState, useEffect } from 'react';
import style from '../Style/subscription.module.css';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import Arrow from './icons/back Vector';
import Button from './button';
import axios from 'axios';
import { antdMessageError, antdMessageSuccess } from '../common/antdMessageComponent';
import { logOutHandlerForMobile } from '../../utills/validator';


const Subscription = ({ setscreen,rightvaluepassed,color }) => {
  const [data, setdata] = useState([]);
  const [Subscriptions, setsubscriptions] = useState([]);
  const [showCancelButton,setShowCancelButton] = useState(false);

  const [active, setActive] = useState([]);
  const [inActive, setInActive] = useState([]);

  const [rightvalue, setrightvalue] = useState();

  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const userCode = queryParams.get('accessToken');

  const subscriptionType = (type) =>{
    if(type === 'ONE_TIME'){
      return 'One time' 
    }else{
      return 'Recurring'
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setdata(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError("It seems your account has been logged out. Please try logging in again")
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    } finally {
      // setLoading(false);
    }
  };

  useEffect (()=>{
    if(data?.userProfileCustomization?.showCancelSubscriptionButton === 'HIDE'){
      setShowCancelButton(false)
    }else{
      setShowCancelButton(true)
    }
  },[data])

  function formatDate(isoString) {
    const date = new Date(isoString);
    // Define options for toLocaleDateString
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    // Convert to the desired format
    return date.toLocaleDateString('en-GB', options);
  }

  const cancelSubscription = async (id) => {
    setLoading(true)
    if(localStorage.getItem('accessToken') !== null ){
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/authorization/userSubscriptions/${id}/cancel`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      antdMessageSuccess('Cancelled the Subscription');
    } catch (error) {
      antdMessageError(` Cannot Cancel the Subscription as ${error.response.data.message}`)
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  }else{
      antdMessageError("It seems your account has been logged out, Please try logging in again.");
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
  }
  };

  const getSubscription = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/list-user-subscriptions`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setsubscriptions(response.data);
      setActive(response.data.active);
      setInActive(response.data.inactive);
    } catch (error) {
      console.log(error.message);
      antdMessageError('Cannot get user subscriptions')
    }
  };


  useEffect(() => {
    fetchData();
    getSubscription();
  }, []);

  // const filterSubscriptions = (Subscriptions) =>{
  //   if()
  // }

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerposition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue();
    }
  }, [data]);
  return (
    <>
      {loading ? (
        <div
        className={style['outercontainer']}
        style={{
          background:color,
          right: rightvaluepassed,
        }}>
          
          <div className={style['loader-container']}>
          <div className={style['loader']}></div>
           </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvaluepassed,
            background: data?.userProfileCustomization?.backgroundColor || '#120238',
            fontFamily: data?.userProfileCustomization?.fontStyle || 'Roboto',
            color: data?.userProfileCustomization?.primaryTextColor || '#fff',
          }}
        >
          <div className={style['head']} style={{ borderBottom:`0.8px solid ${data?.userProfileCustomization?.primaryTextColor}`}}>
            <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
              <Arrow fill={data?.userProfileCustomization?.primaryTextColor || '#fff'} />
            </div>
            <div className={style['topic']}>Your Subscriptions</div>
          </div>

          <div className={style['content']}>
            <div className={style['active']}>
              Active subscriptions
              {(active === 0) ? (
                <div className={style['buynow']}>
                  <small style={{ opacity: '0.5' }}>No Active Subscription</small>
                  <Button text="View Plans" color={data?.userProfileCustomization?.highlightColor || '#E44F91'} />
                </div>
              ) : (
                active.map(item =>(
                  <div
                  className={style['list']}
                  style={{ background: data?.userProfileCustomization?.inputFieldColor || '#201046' }}
                >
                  <div className={style['list-item']}>
                    <span>Name : </span>
                    <span>{item.name}</span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Expires on : </span>
                    <span>{formatDate(item.expiryDate)}</span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Duration : </span>
                    <span>{`${item.duration} Months`}</span>
                  </div>
                  <div className={style['list-item']}>
                    <span>Subscription type : </span>
                    <span>{subscriptionType(item.subscriptionType)}</span>
                  </div>
                  
                  { (item.isCancelable && showCancelButton) && <div className={style['list-item']}>
                      <span className={style['cancelsubs']} onClick={()=>{cancelSubscription(item._id)}}>
                        Cancel Subscription
                      </span>
                  </div>}
                
                </div>
                ))
              )}
            </div>

            <div className={style['inactive']}>
              In-active subscriptions
              {inActive.map(item => (
              <div
                className={style['list']}
                style={{ background: data?.userProfileCustomization?.inputFieldColor || '#201046' }}
              >
                <div className={style['list-item']}>
                  <span>Name : </span>
                  <span>{item.name}</span>
                </div>
                <div className={style['list-item']}>
                  <span>Expired on : </span>
                  <span>{formatDate(item.expiryDate)}</span>
                </div>
                <div className={style['list-item']}>
                  <span>Duration : </span>
                  <span>{`${item.duration} Months`}</span>
                </div>
                <div className={style['list-item']}>
                  <span>Subscription type : </span>
                  <span>{subscriptionType(item.subscriptionType)}</span>
                </div>
              </div> ))
              
            }
              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
