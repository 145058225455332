import axios from 'axios';
import { loginHandlerForMobile } from './validator';
import { antdMessageError } from '../Components/common/antdMessageComponent';

export async function handleAuthCodeGeneration(code, redirectUrl, commanUrlValue, clientId, navigate, clientDetails) {
  try {
    const codedata = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
      headers: { Authorization: `Bearer ${code}` },
    });
    console.log(redirectUrl, 'testing123');
    if (redirectUrl) {
      if (redirectUrl === 'https://conscent-app-sdk') {
        try {
          const dataformobile = {
            token: codedata.data.authCode,
            status: true,
          };
          loginHandlerForMobile(JSON.stringify(dataformobile));
        } catch (err) {
          console.log(err, 'error while sending data to mobile app');
        }
      } else {
        const fetchRedirectUrl = new URL(redirectUrl);
        fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
        if (commanUrlValue.origin === 'subsPage') {
          if (commanUrlValue.purchaseType === null) {
            if (clientDetails?.fullName && clientDetails?.email && clientDetails?.phone) {
              fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
              fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);

              window.location.href = fetchRedirectUrl.href;
              return;
            }
          }
          navigate(`/address?clientId=${clientId}&purchaseType=${commanUrlValue.purchaseType}`);
        } else {
          fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
          fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);

          window.location.href = fetchRedirectUrl.href;
        }
      }
    } else {
      antdMessageError(' A redirect URL is required but not provided. Please check and try again.');
    }
  } catch (err) {
    console.log(err, 'error on generating auth code');
  }
}
