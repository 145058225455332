export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const ValidateMobileNumber = (phoneNubmer) => {
  const re = /^[6-9][0-9]{9}$/;
  return re.test(phoneNubmer);
};
export function loginHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.loginHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler', value);
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.loginHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.LOGINCHALLENGE?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGIN', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}

export function googleLogInHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.googleLogInClickHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.googleLogInClick.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('GOOGLE_LOGIN_CLICK', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}
export const validate = (item, rules) => {
  if (rules.minLength && item.length < rules.minLength) return false;
  if (rules.maxLength && item.length > rules.maxLength) return false;
  if (rules.pattern && !rules.pattern.test(item)) return false;
  return true;
};
export const checkSuccessCode = (response) => {
  return Math.floor(response.status / 100) === 2;
};

export const matchStatusCode = (response, statusCode) => {
  return response.status === statusCode ? true : false;
};

export function logOutHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.logOutHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect handler', value);
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.logOutHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.LOGOUTHANDLER?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('LOGOUT', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}
export function errorHandlerForMobile(value) {
  // for android

  try {
    /* eslint-disable */
    redirectionHandler?.errorHandler(value);
    /* eslint-enable */
  } catch (error) {
    // console.log()
    console.warn('did not find android redirect errorHandler');
  }

  //for IOS
  try {
    // @ts-ignore
    window.webkit.messageHandlers.errorHandler.postMessage(value);
  } catch (error) {
    console.warn('did not find ios redirect handler', value);
  }

  // for flutter
  try {
    // @ts-ignore
    window?.ERRORHANDLER?.postMessage(value);
  } catch (error) {
    console.warn('did not find flutter redirect handler');
  }

  // for flutter-2-package
  try {
    // @ts-ignore
    window.flutter_inappwebview.callHandler('ERROR', value);
  } catch (error) {
    console.warn('did not find flutter 2 handler');
  }

  // for React-Native
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(value);
  } catch (error) {
    console.warn('did not find ReactNativeWebView handler');
  }
}
