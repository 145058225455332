import React, { useState } from "react";
import clsx from "clsx";
import style from "./commonAntdSelectedBox.module.scss";
import { ReactComponent as DropDownArrow } from "../../Components/assets/loginsettings/dropDownArrow.svg";
import { Select } from "antd";

const CustomDropDownForSelect = ({
  options,
  defaultValueForSelectOption,
  onChangeForSelect,
  onSearch,
  label,
  customStyle,
  backGroundstyle,
  showSearch,
  dropdownStyle,
  placeholder,
  defaultValue,
  disabled,
  mode,
  filterOption,
  getPopupContainer,
  tagRender,
  maxTagCount,
  hoverColor,
  onKeyDown,
}) => {
  const [hover, setHover] = useState(false);
  const hoverEffect = customStyle?.border
    ? hover
      ? `1px solid ${hoverColor ? hoverColor :'#0353e9'}`
      : customStyle?.border
    : "none";
  const selectOptions = (selectOptionItem, selectOptionLabel) => {
    let value = "";
    if (selectOptionLabel) {
      if (
        selectOptionLabel === "Pay-Per-Use" ||
        selectOptionLabel === "Content"
      ) {
        value = "Pay per use";
      } else {
        value = selectOptionLabel;
      }
    } else {
      if (
        selectOptionItem === "CONTENT" ||
        selectOptionItem === "Pay-Per-Use"
      ) {
        value = "Pay per use";
      } else {
        value =
          selectOptionItem.charAt(0).toUpperCase() +
          selectOptionItem.slice(1).toLowerCase();
      }
    }
    return value;
  };
  return (
    <>
      <div
        style={{
          ...customStyle,
          border: hoverEffect,
        }}
        className={style["main-container"]}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Select
          mode={mode}
          filterOption={filterOption}
          showSearch={showSearch}
          placeholder={placeholder}
          tagRender={tagRender}
          className={clsx(
            style["common-select-box"],
            style[backGroundstyle || ""]
          )}
          dropdownStyle={dropdownStyle || { zIndex: 0 }}
          popupClassName={clsx(style["common-dropdown-select"])}
          suffixIcon={<DropDownArrow />}
          value={defaultValueForSelectOption}
          // showArrow={true}
          onSearch={onSearch}
          onChange={onChangeForSelect}
          defaultValue={defaultValue}
          disabled={disabled}
          getPopupContainer={getPopupContainer}
          maxTagCount={maxTagCount}
          onKeyDown={onKeyDown}
        >
          {options?.array?.map((item, index) => {
            const selectOptionItem = options?.key
              ? item[`${options?.key}`]
              : item;
            const selectOptionLabel = label?.key
              ? label?.array[index][`${label?.key}`]
              : label?.array[index];
            return (
              <Select.Option
                key={index}
                value={selectOptionItem}
                className={style["dropdown-options"]}
              >
                {selectOptions(selectOptionItem, selectOptionLabel)}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </>
  );
};
export default CustomDropDownForSelect;
