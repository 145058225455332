import React, { useEffect } from 'react';
import { useState } from 'react';
import style from '../Style/Login.module.css';
import { useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogInHandlerForMobile, loginHandlerForMobile, errorHandlerForMobile } from '../../utills/validator';

import user from '../assets/user.svg';
import google from '../assets/google.svg';
import apple from '../assets/apple.svg';
import facebook from '../assets/facebook.svg';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { eventType, eventLocation, clickAction, CSCSendEvent } from '../../analytics';
import { ValidateMobileNumber, validateEmail } from '../../utills/validator';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
const SignIn = () => {
  const uiconfigdata = useSelector((state) => state.clientConfig);
  const uiconfigparsed = useSelector((state) => state.parsedValue.signIn);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);

  const navigate = useNavigate();
  const [phoneNubmer, setPhoneNumber] = useState('');
  const [loginType, setLoginType] = useState('');
  const [uiConfig, setuiConfig] = useState({});
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const clientId = queryParams.get('clientId');
  const anonId = queryParams.get('anonId');
  const googleIdToken = queryParams.get('google_id_token');
  const mobileView = queryParams.get('mobileView') || false;

  useEffect(() => {
    if (uiconfigparsed?.SignIn) {
      setuiConfig(uiconfigparsed?.SignIn);
    }
  }, [uiconfigparsed?.SignIn]);

  useEffect(() => {
    (async () => {
      const userCode = localStorage.getItem('code') || '';
      const queryParams = new URLSearchParams(window.location.search);
      const redirectUrl = queryParams.get('redirectUrl');
      window.localStorage.setItem('redirectUrl', redirectUrl);
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(
                  `/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${commanUrlValue.purchaseType}`,
                );
                // history.push(`/address?clientId=${clientId}&redirectUrl=${redirectUrl}`)

                //  window.location.href=`${window.location.origin}/address?clientId=${clientId}&redirectUrl=${redirectUrl}`
              } else {
                // alert('hii i am in addreess')
                window.location.href = fetchRedirectUrl.href;
              }
            }
          } else {
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          console.log(err, 'error on generating auth code');
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (uiconfigdata && uiconfigdata.groupId && googleIdToken) {
      try {
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/sso/google-one-tap-login`,
          data: {
            token: googleIdToken,
            clientGroupId: uiconfigdata.groupId,
            clientId,
          },
        })
          .then((googleLoginResponse) => {
            if (redirectUrl === 'https://conscent-app-sdk') {
              const dataformobile = {
                token: googleLoginResponse.data.authorizationCode,
                status: true,
              };
              loginHandlerForMobile(JSON.stringify(dataformobile));
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', googleLoginResponse.data.authorizationCode);
              localStorage.setItem('code', googleLoginResponse.data.authorizationCode);
              window.location.href = fetchRedirectUrl.href;
            }
          })
          .catch((error) => {
            console.error('error logging in with google id token', error);
            const fetchRedirectUrl = new URL(redirectUrl);
            fetchRedirectUrl.searchParams.set('loginError', 'error loggging in with google');
            const value = JSON.stringify({ message: 'GOOGLE one tap login error', errorCode: '400' });

            errorHandlerForMobile(value);
            window.location.href = fetchRedirectUrl.href;
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [uiconfigdata]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        if (enableSubmitButton) {
          sendOTP();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [enableSubmitButton]);

  async function sendOTP() {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/sso/login`, {
        clientGroupId: uiconfigdata.groupId,
        phone: phoneNubmer,
        email: email,
        clientId,
        paywallId: commanUrlValue.paywallId,
        paywallType: commanUrlValue.paywallType,
      });
      const ssoLoginClickObject = {
        eventType: eventType.CLICK,
        eventLocation: eventLocation.SSO_LOGIN,
        clickAction: loginType,
        clientId: clientId,
        anonId: anonId,
        url: decodeURIComponent(redirectUrl || ''),
      };
      CSCSendEvent(ssoLoginClickObject);
      const purchaseType = queryParams.get('purchaseType') || undefined;
      navigate(
        `/VerifyOTP?redirectUrl=${redirectUrl}&clientId=${clientId}&pageType=${eventLocation.SSO_LOGIN}&anonId=${anonId}&purchaseType=${purchaseType}`,
        {
          state: { phoneNubmer, email, loginType, origin: 'signIn' },
        },
      );
    } catch (err) {
      antdMessageWarning("We couldn't find a registered account. Want to sign up?");
      const value = JSON.stringify({
        message: "We couldn't find a registered account. Want to sign up?",
        errorCode: '404',
      });

      errorHandlerForMobile(value);
      navigate(`/signup?redirectUrl=${redirectUrl}&clientId=${clientId}`, {
        state: { phoneNubmer, email, loginType },
      });
    }
  }
  const navigateToSignup = () => {
    const purchaseType = queryParams.get('purchaseType') || undefined;
    console.log(purchaseType, 'djkfhiuhfiowe');

    navigate(`/signup?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
    // navigate.sear
  };

  const detectedCountry = 'IN';
  const [email, setemail] = useState('');
  const checkinput = (value, detectedCountry) => {
    if (uiconfigdata.enableEmailLogin) {
      if (validateEmail(value.toLowerCase())) {
        setemail(value.toLowerCase());
        setLoginType('EMAIL');
        return setEnableSubmitButton(true);
      }
    }
    if (uiconfigdata.enableMobileLogin) {
      if (ValidateMobileNumber(value)) {
        if (detectedCountry === 'IN') {
          setPhoneNumber(value);
          setLoginType('PHONE');
          return setEnableSubmitButton(true);
        } else {
          return setEnableSubmitButton(false);
        }
      }
    }
    setEnableSubmitButton(false);
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/google-login`,
        data: {
          token: tokenResponse.access_token,
          clientGroupId: uiconfigdata.groupId,
        },
      });
      const fetchRedirectUrl = new URL(redirectUrl);
      fetchRedirectUrl.searchParams.set('authorizationCode', googleLoginResponse.data.authorizationCode);
      window.location.href = fetchRedirectUrl.href;
    },
    flow: 'implicit',
  });
  const callGoogleLogin = () => {
    if (mobileView) {
      googleLogInHandlerForMobile(JSON.stringify({ message: 'GOOGLE_LOGIN_CLICK' }));
    } else {
      handleGoogleLogin();
    }
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.GOOGLE,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  const callFacebookLogin = () => {
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.FACEBOOK,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  useEffect(() => {
    const ssoLoginViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_LOGIN,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
    };
    CSCSendEvent(ssoLoginViewObject);
  }, []);
  const userCode = localStorage.getItem('code') || '';
  if (userCode) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className={style['spinner']}></div>
      </div>
    );
  }
  return (
    <div className={style['main-container']}>
      <div className={style['container']}>
        <div>
          <div className={style['brand']}>
            <img className={style['logo']} src={uiconfigdata.brandLogoUrl} alt="Logo" />
            <p className={style['logMessage']} style={{ color: uiconfigparsed?.mainTitle?.color }}>
              {' '}
              {uiconfigparsed?.mainTitle?.text || 'Welcome back!'}
            </p>
            <p className={style['message']} style={{ color: uiConfig?.subTitle?.color }}>
              {uiconfigparsed?.subTitle?.text || 'Please enter your sign in details below'}
            </p>
          </div>
        </div>
        <div className={style['login-form']}>
          <div className={style['inputFieldContainer']}>
            <img src={user} className={style['inputIcon']} alt="" />
            <input
              onChange={(e) => {
                checkinput(e.target.value, detectedCountry);
              }}
              placeholder="Mobile / Email"
              className={style['inputField']}
              type="text"
              maxLength={60}
            />
          </div>
          <button
            disabled={!enableSubmitButton}
            onClick={() => sendOTP()}
            style={{ opacity: !enableSubmitButton ? '0.5' : '1' }}
            className={style['sign-up-btn']}
          >
            Sign in
          </button>
          <p className={style['already-account']}>
            Don’t have an account?{' '}
            <span
              onClick={() => {
                navigateToSignup();
              }}
              style={{ color: uiConfig?.formTitle?.color }}
              className={style['redirect-sign']}
            >
              {' '}
              {uiconfigparsed?.formTitle?.text || 'Sign Up'}
            </span>
          </p>
        </div>
        <div>
          <div className={style['continueWithContainer']}>
            <hr className={style['horizontalLine']} />
            <div className={style['continueWith']}>Or</div>
            <hr className={style['horizontalLine']} />
          </div>
          <div className={style['sso-login']}>
            {uiconfigdata.enableGoogleLogin ? (
              <div
                onClick={() => {
                  callGoogleLogin();
                }}
                className={style['google-login']}
              >
                <img src={google} alt="" />
                Google
              </div>
            ) : (
              <></>
            )}
            {uiconfigdata.enableFacebookLogin ? (
              <div
                className={style['facebook-login']}
                onClick={() => {
                  callFacebookLogin();
                }}
              >
                <img src={facebook} alt="" />
                Facebook
              </div>
            ) : (
              <></>
            )}
          </div>
          {uiconfigdata.enableAppleLogin ? (
            <div className={style['apple-login']}>
              <img src={apple} alt="" />
              Apple
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignIn;
