import React, { useEffect, useState } from 'react';
import style from './address.module.css';
import loginStyle from '../NewLogin/review-screens.module.scss';
import antdSelectStyle from '../../Ui/DropDown/commonAntdSelectedBox.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addUserInfo } from '../../../src/Reducer/commonData';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Search_icon from '../assets/loginsettings/search_icon.svg';
import { ReactComponent as DropDownArrow } from '../assets/loginsettings/dropDownArrow.svg';
import { Country_flag_json } from '../common/country-flag-json';
import { CSCSendEvent } from '../../analytics';
import { checkSuccessCode, validateEmail } from '../../utills/validator';
import { Input, Select, message } from 'antd';
import { States } from '../../utills/consts';
import axios from 'axios';
import loderStyle from '../Style/Loader.module.css';
import Verify_Icon from '../assets/loginsettings/verify_icon.svg';
import NewLoader from '../../utills/NewLoader';
import clsx from 'clsx';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
const queryParams = new URLSearchParams(window.location.search);
const purchaseType = queryParams.get('purchaseType');
const isEdit = queryParams.get('edit');

const addressPageView = (data, userId) => {
  const addressPageViewObject = {
    clientId: data.clientId,
    eventType: 'VIEW',
    eventLocation: 'SAP',
    anonId: data.anonId,
    paywallId: data.paywallId,
    userId,
  };
  CSCSendEvent(addressPageViewObject);
};
const sendClickevent = (data, userId) => {
  const addressClickObject = {
    clientId: data.clientId,
    clickAction: purchaseType === 'physical' ? 'ADD_ADDRESS' : 'ADD_PERSONAL_DATA',
    eventType: 'CLICK',
    eventLocation: 'SAP',
    anonId: data.anonId,
    paywallId: data.paywallId,
    userId,
  };
  CSCSendEvent(addressClickObject);
};

const Address = () => {
  const data = useSelector((state) => state.newClientConfig.login);
  const toggleData = useSelector((state) => state.loginSetting);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const dispatch = useDispatch();
  const clientConfigdata = useSelector((state) => state.clientConfig);
  const [checkdata, setCheckdata] = useState(true);
  const [userTempData, setUserTempData] = useState({});
  const [inputValue, SetInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [detectedCountry, setDetectedCountry] = useState('IN');
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([{ name: 'India', code: 'IN' }]);
  const onInput = (type, e) => {
    setUserDetails({
      ...userDetails,
      [type]: { ...userDetails[type], value: e.target.value },
    });
  };

  const showError = (error) => {
    messageApi.open({
      type: 'error',
      content: error,
    });
  };

  useEffect(() => {
    (async () => {
      const addressDetails = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/sso/me`,

        headers: { Authorization: `Bearer ${localStorage.getItem('code')}` },
      });
      setUserTempData({ ...addressDetails.data });
      if (
        addressDetails.data.email &&
        addressDetails.data.name &&
        addressDetails.data.phone &&
        purchaseType != 'physical' &&
        isEdit != 'true'
      ) {
        const redirectUrl = localStorage.getItem('redirectUrl');
        const code = localStorage.getItem('code');
        const fetchRedirectUrl = new URL(redirectUrl);
        const codedata = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
          headers: { Authorization: `Bearer ${code}` },
        });
        fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
        fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
        fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);
        if (purchaseType === 'physical') {
          fetchRedirectUrl.searchParams.set('purchaseType', purchaseType);
          fetchRedirectUrl.searchParams.set('editUrl', window.location.href);
        }
        window.location.href = fetchRedirectUrl.href;
      } else {
        setCheckdata(false);
      }

      // setCheckdata()
      console.log(addressDetails.data, 'dsjkfhuisdhfiosjf');
      addressPageView(commanUrlValue, addressDetails.data._id);

      dispatch(addUserInfo({ ...addressDetails.data }));

      const country = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/detect-country`,
      });
      setDetectedCountry(country?.data?.countryCode);
    })();
  }, []);

  useEffect(() => {
    setCountries(
      clientConfigdata?.physicalCountries?.map((c) => {
        const country = Country_flag_json.find((el) => el.code === c);
        return { name: country.name, code: country.code };
      }) || [],
    );
  }, [clientConfigdata?.physicalCountries]);

  const [userDetails, setUserDetails] = useState({
    name: { value: userTempData?.name },
    email: {
      value: userTempData?.email,
      isVerified: userTempData?.isEmailVerified,
    },
    phone: {
      value: userTempData?.phone,
      isVerified: userTempData?.isPhoneVerified,
    },
    countryCode: { value: userTempData?.countryCode || '+91' },
    apartment: { value: userTempData?.address?.apartment },
    area: { value: userTempData?.address?.area },
    landmark: { value: userTempData?.address?.landmark },
    country: { value: userTempData?.address?.country },
    state: { value: userTempData?.address?.state },
    city: { value: userTempData?.address?.city },
    pincode: { value: userTempData?.address?.pincode },
  });

  useEffect(() => {
    if (Object.keys(userTempData).length != 0) {
      setUserDetails({
        name: { value: userTempData?.name },
        email: {
          value: userTempData?.email,
          isVerified: userTempData?.isEmailVerified,
        },
        phone: {
          value: userTempData?.phone,
          isVerified: userTempData?.isPhoneVerified,
        },
        countryCode: { value: userTempData?.countryCode },
        apartment: { value: userTempData?.address?.apartment },
        area: { value: userTempData?.address?.area },
        landmark: { value: userTempData?.address?.landmark },
        country: { value: userTempData?.address?.country },
        state: { value: userTempData?.address?.state },
        city: { value: userTempData?.address?.city },
        pincode: { value: userTempData?.address?.pincode },
      });
    }
  }, [userTempData]);

  const editAccount = async () => {
    setLoading(true);
    sendClickevent(commanUrlValue, userTempData._id);
    if (userDetails?.name?.value === undefined || userDetails?.name?.value?.length < 3) {
      setLoading(false);
      return showError('enter name of more than 2 characters');
    }
    if (!userDetails?.email?.value || !validateEmail(userDetails?.email?.value)) {
      setLoading(false);
      return showError('Enter a valid Email');
    }
    if (!userDetails?.phone?.value) {
      setLoading(false);
      return showError('phone is required');
    }

    if (purchaseType === 'physical') {
      if (!userDetails?.apartment?.value) {
        setLoading(false);
        return showError('Enter a valid House/flat/Apartment');
      }
      if (!userDetails?.area?.value) {
        setLoading(false);
        return showError('Enter a valid Area');
      }
      if (!userDetails?.country?.value) {
        setLoading(false);
        return showError('Enter a valid Country');
      }
      if (!userDetails?.city?.value) {
        setLoading(false);
        return showError('Enter a valid city');
      }
      if (!userDetails?.pincode?.value) {
        setLoading(false);
        return showError('Enter a valid pincode');
      }
    }
    let userData = {};
    try {
      userData = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/edit-account`,
        data: {
          name: userDetails?.name?.value,
          ...(!userDetails?.phone?.isVerified && {
            phone: `${userDetails?.countryCode?.value}${userDetails?.phone?.value}`,
          }),
          ...(!userDetails?.email?.isVerified && {
            email: userDetails?.email?.value,
          }),
          clientId: clientConfigdata.clientId,
          clientGroupId: clientConfigdata.groupId,
          phoneCountryCode: userDetails.countryCode.value || '+91',
          address: {
            apartment: userDetails?.apartment?.value,
            area: userDetails?.area?.value,
            pincode: userDetails?.pincode?.value,
            landmark: userDetails?.landmark?.value,
            city: userDetails?.city?.value,
            state: userDetails?.state?.value,
            country: userDetails?.country?.value,
          },
          phone: userDetails.phone.value,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('code')}` },
      });
    } catch (error) {
      setLoading(false);
      for (const key in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(key)) {
          showError(error.response.data.errors[key]);
        }
      }
    }

    if (userData.status === 201) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      const code = localStorage.getItem('code');
      const fetchRedirectUrl = new URL(redirectUrl);
      const codedata = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
        headers: { Authorization: `Bearer ${code}` },
      });
      fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
      setLoading(false);
    }

    if (!checkSuccessCode(userData)) {
      setLoading(false);
      return;
    } else {
      const redirectUrl = localStorage.getItem('redirectUrl');
      const code = localStorage.getItem('code');
      const fetchRedirectUrl = new URL(redirectUrl);
      const codedata = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
        headers: { Authorization: `Bearer ${code}` },
      });
      fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
      fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
      fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);
      if (purchaseType === 'physical') {
        fetchRedirectUrl.searchParams.set('purchaseType', purchaseType);
        fetchRedirectUrl.searchParams.set('editUrl', window.location.href);
      }
      setLoading(false);
      window.location.href = fetchRedirectUrl.href;
    }
  };

  const OnCountryChange = (v) => {
    setUserDetails({ ...userDetails, country: { value: v.toString() } });
    if (v.toString() === 'IN' || v.toString() === 'US' || v.toString() === 'CA') {
      setStates(States[v.toString()]);
    }
  };

  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase()),
  );
  const dropdownRender = (menu) => (
    <div>
      <div className={loginStyle['dropDown-search']}>
        <Input placeholder="Search..." onChange={(e) => SetInputValue(e.target.value)} />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );

  return (
    <div>
      {checkdata && <NewLoader />}
      {contextHolder}
      <div className={style['main-container']}>
        <div className={style['header-logo']}>
          <img className={style['image-logo']} src={toggleData?.brandLogoUrl} alt="Logo Image" />
        </div>
        <div className={loginStyle['p-4']}></div>
        <div className={style['sub-container']}>
          <div className={style['innner-container']}>
            <div className={style['title']}>ADD / CONFIRM YOUR DETAILS</div>
            <div className={style['input-field']}>
              <input
                placeholder="Full name*"
                type={'text'}
                value={userDetails?.name?.value}
                onChange={(e) => {
                  onInput('name', e);
                }}
              />
            </div>
            <div className={clsx(style['input-field'], style['flex'])}>
              <input
                placeholder="Email*"
                type={'text'}
                style={
                  userDetails?.email?.isVerified
                    ? {
                        backgroundColor: userDetails?.phone?.isVerified && '#EBEBEB',
                        cursor: 'not-allowed',
                        color: 'grey',
                      }
                    : {}
                }
                disabled={userDetails?.email?.isVerified}
                value={userDetails?.email?.value}
                onChange={(e) => {
                  onInput('email', e);
                }}
              />
              {userDetails?.email?.isVerified && <img src={Verify_Icon} alt="Verify_Icon" />}
            </div>
            <div className={style['phone-field']}>
              <div
                className={style['mobile-code']}
                style={{ backgroundColor: userDetails?.phone?.isVerified && '#EBEBEB' }}
              >
                <img src={Mobile_icon} alt="Mobile_icon" />
                <div className={loginStyle['dropDown-container']}>
                  <Select
                    dropdownRender={dropdownRender}
                    popupMatchSelectWidth={250}
                    filterOption={false}
                    variant="borderless"
                    suffixIcon={<DropDownArrow />}
                    disabled={userDetails?.phone?.isVerified}
                    className={antdSelectStyle['common-select-box']}
                    popupClassName={antdSelectStyle['common-dropdown-select']}
                    value={userDetails?.countryCode?.value || '+91'}
                    onChange={(v) =>
                      setUserDetails({
                        ...userDetails,
                        countryCode: { value: v },
                      })
                    }
                    optionLabelProp="label"
                  >
                    {filteredOptions.map((option, index) => (
                      <Select.Option
                        key={index}
                        value={option.dail_code}
                        label={option.dail_code}
                        className={antdSelectStyle['dropdown-options']}
                      >
                        <div className={loginStyle['dropdown-flag']}>
                          <div className={loginStyle['flag-code']} style={{ fontSize: '12px' }}>
                            {option.dail_code}
                          </div>
                          <div className={loginStyle['flag-name']}>{option.name}</div>
                          <div className={loginStyle['flag']}>{option.flag}</div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div
                className={style['input-field']}
                style={{ marginBottom: purchaseType === 'physical' ? '25px' : '0px' }}
              >
                <input
                  type="text"
                  placeholder="Mobile number*"
                  maxLength={detectedCountry === 'IN' ? 10 : 15}
                  value={userDetails?.phone?.value}
                  onChange={(e) => {
                    const reg = /^-?\d*(\.\d*)?$/;
                    if (reg.test(e.target.value) || e.target.value === '') {
                      onInput('phone', e);
                    }
                  }}
                  disabled={userDetails?.phone?.isVerified}
                  autoComplete={'off'}
                  style={
                    userDetails?.phone?.isVerified
                      ? {
                          backgroundColor: userDetails?.phone?.isVerified && '#EBEBEB',
                          cursor: 'not-allowed',
                          color: 'grey',
                        }
                      : {}
                  }
                />
              </div>
            </div>
            {purchaseType === 'physical' && (
              <>
                <div className={style['delivery-address']}>Kindly provide your delivery address</div>
                <div className={style['input-field']}>
                  <input
                    placeholder="House / Flat / Apartment*"
                    type={'text'}
                    value={userDetails.apartment.value}
                    onChange={(e) => {
                      onInput('apartment', e);
                    }}
                  />
                </div>
                <div className={style['input-field']}>
                  <input
                    placeholder="Street / Block / Area*"
                    type={'text'}
                    value={userDetails.area.value}
                    onChange={(e) => {
                      onInput('area', e);
                    }}
                  />
                </div>
                <div className={style['input-field']}>
                  <input
                    placeholder="Landmark (optional)"
                    type={'text'}
                    value={userDetails.landmark.value}
                    onChange={(e) => {
                      onInput('landmark', e);
                    }}
                  />
                </div>
                <div className={style['flex']} style={{ marginBottom: '12px' }}>
                  <CustomDropDownForSelect
                    placeholder={'Select Country*'}
                    options={{ array: countries, key: 'code' }}
                    label={{ array: countries, key: 'name' }}
                    onChangeForSelect={OnCountryChange}
                    defaultValueForSelectOption={userDetails.country.value || 'Select Country*'}
                    customStyle={{
                      border: '1px solid #ADADAD',
                      fontFamily: 'Montserrat',
                      height: '38px',
                      width: '50%',
                    }}
                    dropdownStyle={{ zIndex: 1 }}
                    hoverColor="#ADADAD"
                  />
                  {!states?.length ? (
                    <div className={style['input-field']} style={{ marginBottom: '0px', width: '50%' }}>
                      <input
                        placeholder="State/Province*"
                        type={'text'}
                        value={userDetails.state.value}
                        onChange={(e) => {
                          onInput('state', e);
                        }}
                      />
                    </div>
                  ) : (
                    <CustomDropDownForSelect
                      placeholder={'Select State/Province*'}
                      options={{ array: states }}
                      onChangeForSelect={(v) =>
                        setUserDetails({
                          ...userDetails,
                          state: { value: v.toString() },
                        })
                      }
                      defaultValueForSelectOption={userDetails.state.value || 'Select State/Province*'}
                      customStyle={{
                        border: '1px solid #ADADAD',
                        fontFamily: 'Montserrat',
                        height: '38px',
                        width: '50%',
                      }}
                      dropdownStyle={{ zIndex: 1 }}
                      hoverColor="#ADADAD"
                    />
                  )}
                </div>
                <div className={style['flex']}>
                  <div className={style['input-field']} style={{ marginBottom: '0px' }}>
                    <input
                      type="text"
                      placeholder="City*"
                      value={userDetails.city.value}
                      onChange={(e) => {
                        onInput('city', e);
                      }}
                    />
                  </div>
                  <div className={style['input-field']} style={{ marginBottom: '0px' }}>
                    <input
                      type="text"
                      placeholder="Postal Code / Zip Code*"
                      value={userDetails.pincode.value}
                      onChange={(e) => {
                        onInput('pincode', e);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <button
              className={style['submitBtn']}
              onClick={editAccount}
              disabled={loading}
              style={{ ...data?.buttonText }}
            >
              {loading ? (
                <div className={loderStyle.loader}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                'Continue to Payment'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Address;
